<template>
  <!-- END TOP AREA  -->
  <div class="home-main-bg">
    <div class="container-fluid px-lg-5">
      <div class="home-main-wrapper-left">
        <h2 class="home-main-wrapper-left-1">Welcome to Trepr!</h2>
        <div class="home-main-wrapper-left-2 mb-3">
          Are you looking for a <b>flight companion?</b>
        </div>
        <svg class="d-none">
          <defs>
            <path id="tabshape" d="M80,60C34,53.5,64.417,0,0,0v60H80z"></path>
          </defs>
        </svg>
        <div class="home-search-container mini text-center">
          <div class="search-bar-home">
            <div class="row">
              <div class="col-md-12 tabs-style-shape">
                <ul class="nav ps-0 mb-0" role="tablist">
                  <li class="nav-item tab-current" role="presentation">
                    <a
                      class="nav-link active"
                      id="peopleTab"
                      data-bs-toggle="tab"
                      data-bs-target="#peopleOpen"
                      type="button"
                      role="tab"
                      aria-controls="peopleOpen"
                      aria-selected="true"
                    >
                      <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                        <use xlink:href="#tabshape"></use>
                      </svg>
                      <span>Flight Companionship</span>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="packageTab"
                      data-bs-toggle="tab"
                      data-bs-target="#packageOpen"
                      type="button"
                      role="tab"
                      aria-controls="packageOpen"
                      aria-selected="false"
                    >
                      <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                        <use xlink:href="#tabshape"></use>
                      </svg>
                      <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                        <use xlink:href="#tabshape"></use>
                      </svg>
                      <span>Package Shipment</span>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation">
                    <a
                      class="nav-link"
                      id="productTab"
                      data-bs-toggle="tab"
                      data-bs-target="#productOpen"
                      type="button"
                      role="tab"
                      aria-controls="productOpen"
                      aria-selected="false"
                    >
                      <svg viewBox="0 0 80 60" preserveAspectRatio="none">
                        <use xlink:href="#tabshape"></use>
                      </svg>
                      <span>Shopping Concierge</span>
                    </a>
                  </li>
                </ul>
                <hr class="tab-home-search-line mb-3" />
                <div class="tab-content" id="myTabContent">
                  <div
                    class="tab-pane fade show active"
                    id="peopleOpen"
                    role="tabpanel"
                    aria-labelledby="peopleTab"
                  >
                    <form
                      id="homesearchPeople"
                      action="/seeker/browse-travellers"
                      method="post"
                      autocomplete="off"
                    >
                      <input
                        name="trepservice"
                        id="trepservice_people"
                        type="hidden"
                        value="people"
                      />
                      <div class="row mb-3 mt-sm-3">
                        <div class="col-md-12">
                          <input
                            type="text"
                            placeholder="Select Origin Airport or City"
                            class="orgin input typeahead ui-autocomplete-input form-control"
                            id="origin_people"
                            name="origin"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <input
                            type="text"
                            placeholder="Select Destination Airport or City"
                            class="form-control"
                            id="destination_people"
                            name="destination"
                            autocomplete="off"
                          />
                        </div>
                      </div>

                      <div class="row mt-3 g-lg-1 g-3">
                        <div class="col-md-4">
                          <input
                            class="form-control"
                            type="text"
                            name="date"
                            placeholder="Select Date"
                            autocomplete="off"
                          />
                        </div>
                        <div class="col-md-4">
                          <button type="button" class="btn btn-primary w-100">
                            <i class="fa fa-search" aria-hidden="true"></i>&nbsp;<span>Search</span>
                          </button>
                        </div>
                        <div class="col-md-4">
                          <button type="button" class="btn btn-secondary w-100">
                            <i class="fas fa-hand-holding-usd"></i>&nbsp; Get Rates
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="packageOpen"
                    role="tabpanel"
                    aria-labelledby="packageTab"
                  >
                    <form
                      id="homesearchPackage"
                      action="/seeker/browse-travellers"
                      method="post"
                      autocomplete="off"
                    >
                      <input
                        name="trepservice"
                        id="trepservice_package"
                        type="hidden"
                        value="package"
                      />
                      <div class="row">
                        <div class="col-md-12 mb-3">
                          <input
                            type="text"
                            placeholder="Select Pickup Location or City"
                            class="orgin input typeahead ui-autocomplete-input form-control"
                            id="origin_package"
                            name="origin"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <input
                            type="text"
                            placeholder="Select Delivery Location or City "
                            class="destination input typeahead ui-autocomplete-input form-control"
                            id="destination_package"
                            name="destination"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <div class="row mt-3 g-lg-1 g-3">
                        <div class="col-md-4">
                          <input
                            class="form-control"
                            type="text"
                            name="date"
                            placeholder="Select Date"
                            autocomplete="off"
                          />
                        </div>
                        <div class="col-md-4">
                          <button type="button" class="btn btn-primary w-100">
                            <i class="fa fa-search" aria-hidden="true"></i>&nbsp;<span>Search</span>
                          </button>
                        </div>
                        <div class="col-md-4">
                          <button type="button" class="btn btn-secondary w-100">
                            <i class="fas fa-hand-holding-usd"></i>&nbsp; Get Rates
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="productOpen"
                    role="tabpanel"
                    aria-labelledby="productTab"
                  >
                    <form
                      id="homesearchProduct"
                      action="/seeker/browse-travellers"
                      method="post"
                      autocomplete="off"
                    >
                      <input
                        name="trepservice"
                        id="trepservice_project"
                        type="hidden"
                        value="project"
                      />
                      <div class="row">
                        <div class="col-md-12 mb-3">
                          <input
                            type="text"
                            placeholder="Select Product Location or City"
                            class="orgin input typeahead ui-autocomplete-input form-control"
                            id="origin_project"
                            name="origin"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <input
                            type="text"
                            placeholder="Select Delivery Location or City"
                            class="destination input typeahead ui-autocomplete-input form-control"
                            id="destination_project"
                            name="destination"
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      <div class="row mt-3 g-lg-1 g-3">
                        <div class="col-md-4">
                          <input
                            class="form-control"
                            type="text"
                            name="date"
                            placeholder="Select Date"
                            autocomplete="off"
                          />
                        </div>
                        <div class="col-md-4">
                          <button type="button" class="btn btn-primary w-100">
                            <i class="fa fa-search" aria-hidden="true"></i>&nbsp;<span>Search</span>
                          </button>
                        </div>
                        <div class="col-md-4">
                          <button type="button" class="btn btn-secondary w-100">
                            <i class="fas fa-hand-holding-usd"></i>&nbsp; Get Rates
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="clear-both"></div>
  <div class="container-fluid px-lg-5">
    <div class="row">
      <div class="col-sm-12 text-center text-dark mt-4">
        <h1 class="text-primary">Flight Companion & Parcel Delivery Services</h1>
        <h2 class="text-secondary">Our Services</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 text-center text-md-start">
        <img
          src="/img/home/traveller_people.svg"
          alt="International Flight Companion"
          class="img-fluid w-75"
        />
      </div>
      <div class="col-md-6 d-flex">
        <div class="align-self-center text-center text-md-start">
          <h3 class="text-primary">
            <i class="fa-plane-departure fas text-secondary rounded-circle"></i>
            Flight Companion
          </h3>
          <div class="lead text-justify">
            Use our travel buddy finder services to get matched with an international travel
            companion heading to the same destination for assistance. Your flight companion will
            ensure your air travel is safe and comfortable.
          </div>

          <!--          <button type="button" class="btn btn-lg btn-secondary mt-3 border-radius-10">-->
          <router-link
            :to="{ name: 'FindCompanion' }"
            class="text-white btn btn-lg btn-secondary mt-3 border-radius-10"
          >
            Find Companion
          </router-link>
          <!--          </button>-->
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 d-flex order-2 order-md-1">
        <div class="align-self-center text-center text-md-start">
          <h3 class="text-primary">
            <i class="fa-shipping-fast fas text-secondary rounded-circle"></i>Package Shipment
          </h3>
          <div class="lead text-justify">
            Use our crowd shipping platform to courier a package from your place of origin to your
            preferred destination. Our international peer-to-peer delivery system ensures
            affordable, reliable, safe, and timely package delivery.
          </div>
          <router-link
            :to="{ name: 'PackageShipment' }"
            class="text-white btn btn-lg btn-secondary mt-3 border-radius-10"
          >
            Get package delivered
          </router-link>
        </div>
      </div>
      <div class="col-md-6 order-1 order-md-2 text-center text-md-end">
        <img
          src="/img/home/traveller_package.svg"
          alt="Package Shipment - International Parcel Delivery Services"
          class="img-fluid w-75"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 text-center text-md-start">
        <img
          src="/img/home/traveller_product.svg"
          alt="Shopping Concierge"
          class="img-fluid w-75"
        />
      </div>
      <div class="col-md-6 d-flex">
        <div class="align-self-center text-center text-md-start">
          <h3 class="text-primary">
            <i class="fa-handshake fas text-secondary rounded-circle"></i>Shopping Concierge
          </h3>
          <div class="lead text-justify">
            Have eyes on a product that is difficult to find in your country and is cheaper
            overseas? We've got you covered with our dropshipping suppliers. With our seamless
            shopping concierge services, you can find a traveler who can make purchases on your
            behalf.
          </div>
          <router-link
            :to="{ name: 'ShoppingConcierge' }"
            class="text-white btn btn-lg btn-secondary mt-3 border-radius-10"
          >
            Shop Internationally
          </router-link>
        </div>
      </div>
    </div>

    <div class="row margin-top-0421">
      <div class="col-sm-12 text-center">
        <h1>About Us</h1>
      </div>
      <div class="col-sm-12 lead text-justify">
        Founded in August of 2015 and based in Belfast, Northern Ireland, UK, Trepr (Trip
        Representative) is one of the peer to peer economy sharing trusted community marketplace for
        people to list, discover, and substitute another person for their travel needs around the
        world.
        <br />
        Almost every person has his/her local or international travel plans either occasionally or
        frequently. Trepr connects flight travelers and service seekers all over the world to
        exchange their travel plan and invoke travel services such as people (Flight Companionship),
        package (Parcel Delivery), and product (Shopping Concierge), safely, cheaply and promptly
        respectively anywhere in the world by substituting a traveler, who travels on the same
        route, and pay for each service through trepr.
      </div>
      <div class="col-sm-12 text-center">
        <button type="button" class="btn btn-lg btn-secondary mt-3 border-radius-10">
          <router-link :to="{ name: 'AboutUs' }" class="text-white">Read More</router-link>
        </button>
      </div>
    </div>
    <div class="row margin-top-0421">
      <div class="col-sm-12 text-center">
        <h1 class="text-dark">What people have to say</h1>
      </div>
      <div class="col-sm-12">
        <div id="slider" class="carousel slide" data-bs-ride="carousel">
          <div class="carousel-indicators" id="slider-indicators">
            <button
              v-for="i in testimonialsPage"
              type="button"
              data-bs-target="#slider"
              :data-bs-slide-to="i - 1"
              :class="{ active: i == 1 }"
              aria-current="true"
              :aria-label="i"
              :key="i"
            ></button>
          </div>
          <div class="carousel-inner">
            <div
              v-for="j in testimonialsPage"
              :key="j"
              :class="{ 'carousel-item': true, active: j == 1 }"
            >
              <div class="row g-1">
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-sm-12 blockquote-wrapper">
                      <div
                        class="
                          blockquote-customer
                          my-0
                          bg-light
                          text-muted
                          fs-5
                          py-4
                          px-3
                          text-justify
                        "
                      >
                        {{ $data.testimonials[(j - 1) * 2].testimonial_Description }}
                      </div>
                    </div>
                    <div class="col-sm-12 mt-4">
                      <div class="d-inline-block">
                        <img
                          :src="
                            '/img/testimonials/small/' +
                            $data.testimonials[(j - 1) * 2].testimonial_Image
                          "
                          width="40"
                          height="40"
                        />
                        <strong> {{ $data.testimonials[(j - 1) * 2].testimonial_Name }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row">
                    <div class="col-sm-12 blockquote-wrapper">
                      <div
                        class="
                          blockquote-customer
                          my-0
                          bg-light
                          text-muted
                          fs-5
                          py-4
                          px-3
                          text-justify
                        "
                      >
                        {{ $data.testimonials[(j - 1) * 2 + 1].testimonial_Description }}
                      </div>
                    </div>
                    <div class="col-sm-12 mt-4">
                      <div class="d-inline-block">
                        <img
                          :src="
                            '/img/testimonials/small/' +
                            $data.testimonials[(j - 1) * 2 + 1].testimonial_Image
                          "
                          width="40"
                          height="40"
                        />
                        <strong> {{ $data.testimonials[(j - 1) * 2 + 1].testimonial_Name }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row margin-top-0421">
      <div class="col-sm-12 text-center">
        <h1 class="text-dark">All Your Questions Answered</h1>
      </div>
      <div class="col-md-6">
        <div class="accordion accordion-flush" id="accordionQaLeft">
          <div class="accordion-item" v-for="i in $data.qa.length / 2" :key="i">
            <h4 class="accordion-header" :id="'flush-' + i">
              <button
                :aria-controls="'qa' + i"
                aria-expanded="false"
                class="accordion-button collapsed"
                :data-bs-target="'#qa' + i"
                data-bs-toggle="collapse"
                type="button"
              >
                {{ $data.qa[i - 1].title }}
              </button>
            </h4>
            <div
              :aria-labelledby="'flush-' + i"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionQaLeft"
              :id="'qa' + i"
            >
              <div class="accordion-body">
                <div
                  v-html="
                    $data.qa[i - 1].content.replace(
                      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                      '$1' + '<br />' + '$2'
                    )
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="accordion accordion-flush" id="accordionQaRight">
          <div class="accordion-item" v-for="i in rightQa" :key="i">
            <h4 class="accordion-header" :id="'flushr-' + i">
              <button
                :aria-controls="'qar' + i"
                aria-expanded="false"
                class="accordion-button collapsed"
                :data-bs-target="'#qar' + i"
                data-bs-toggle="collapse"
                type="button"
              >
                {{ $data.qa[i].title }}
              </button>
            </h4>
            <div
              :aria-labelledby="'flushr-' + i"
              class="accordion-collapse collapse"
              data-bs-parent="#accordionQaRight"
              :id="'qar' + i"
            >
              <div class="accordion-body">
                <div
                  v-html="
                    $data.qa[i].content.replace(
                      /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
                      '$1' + '<br />' + '$2'
                    )
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-sm-12 text-center">
        <button type="button" class="btn btn-lg btn-secondary mt-3 border-radius-10">
          <router-link :to="{ name: 'Faq' }" class="text-white"> Visit Help Center </router-link>
        </button>
      </div>
    </div>
  </div>
  <div class="w-100 bg-light padding-bottom-0421">
    <div class="container-fluid px-lg-5">
      <div class="row margin-top-half-0421 padding-top-half-0421">
        <div class="col-md-8">
          <h1 class="text-dark">
            Marketing Resources: Travel Assistance, Drop Shipping and Package delivery
          </h1>
        </div>
        <div class="col-md-4 text-center">
          <button type="button" class="btn btn-lg btn-secondary mt-3 border-radius-10">
            <router-link :to="{ name: 'BlogList' }" class="text-white">
              Explore All Resources
            </router-link>
          </button>
        </div>
      </div>
      <div class="row margin-top-0421 news-wrapper">
        <div class="col-md-6 col-xl-3 news-section" v-for="i in $data.blogs.length" :key="i">
          <div class="card h-100">
            <router-link :to="{ name: 'Blog1' }">
              <img
                :src="'/uploads/blogs/covers/medium/' + $data.blogs[i - 1].image"
                :alt="$data.blogs[i - 1].title"
                class="card-img-top"
              />
            </router-link>
            <div class="card-body">
              <router-link :to="{ name: 'Blog1' }" class="link-primary">
                <h5 class="card-title text-center">{{ $data.blogs[i - 1].title }}</h5>
              </router-link>
              <div class="card-text text-justify" v-html="$data.blogs[i - 1].short_content"></div>
              <div class="text-center">
                <router-link :to="{ name: 'Blog1' }" class="btn btn-secondary">
                  Read more
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// let bootstrap = require("bootstrap");
import 'bootstrap';

export default {
  name: 'Homepage',
  data: function () {
    return {
      blogs: [],
      qa: [],
      categories: [],
    };
  },
  async created() {
    document.title = 'Trepr - Flight Companionship | Package Shipment | Shopping Concierge';
    document
      .querySelector('meta[name="description"]')
      .setAttribute(
        'content',
        'Are you looking for a professional travel companion? Trepr connects flight travelers & service seekers all over the world to exchange their travel plan & invoke travel services such as people (Flight Companionship), package (Parcel Delivery), & product (Shopping Concierge), safely & cheaply.'
      );
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute(
        'content',
        'Trepr,Travel companion, Package services, Flight companionship, Flight companion for elders, Package shipment, Shopping conceirge'
      );
    let self = this;
    // console.log("state $store ", this.$store.state)
    let apiUrl = process.env.VUE_APP_API_URL;

    await fetch(apiUrl + 'common/get-items-for-homepage', {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((resData) => {
        // console.log('Success:', resData);
        // let messages = resData.messages
        if (resData.status == 'error') {
          alert('error while geting data ' + resData.message);
          return;
        }

        self.$data.testimonials = resData.testimonials;
        self.$data.qa = resData.qa;
        self.$data.blogs = resData.blogs;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/app_home.scss';
</style>
